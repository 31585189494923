export default {
  applications: [
    {
      _id: 'ttn-v3',
      name: 'The Things Network',
      components: {
        main: 'ttn-v3/Overview'
      },
      deviceHeaders: [
        {
          title: 'LoRa Device EUI',
          field: (device) => device.metaData.lorawan.deviceEUI
        }
      ]
    },
    {
      _id: 'tp',
      name: 'ThingPark Network',
      components: {
        main: 'tp/Overview'
      },
      deviceHeaders: [
        {
          title: 'LoRa Device EUI',
          field: (device) => device.metaData.lorawan.deviceEUI
        }
      ]
    },
    {
      _id: 'hylora',
      name: 'HyQuest Private LoRa',
      deviceHeaders: [
        {
          title: 'LoRa Device EUI',
          field: (device) => device.metaData.lorawan.deviceEUI
        }
      ]
    },
    {
      _id: 'ontoto',
      name: 'Ontoto Network',
      components: {
        main: 'ontoto/Overview'
      },
      deviceHeaders: [
        {
          title: 'Serial Number',
          field: (device) => device.metaData.serialNumber
        }
      ]
    },
    {
      _id: 'addupi',
      name: 'Adcon Network',
      components: {
        main: 'addupi/Overview'
      },
      deviceTypes: {
        A764: {
          main: 'adcon/Overview',
          addingConfiguration: 'adcon/AddingConfiguration'
        },
        A765: {
          main: 'adcon/Overview',
          addingConfiguration: 'adcon/AddingConfiguration'
        },
        A760: {
          main: 'adcon/Overview',
          addingConfiguration: 'adcon/AddingConfiguration'
        },
        A755GSM: {
          main: 'adcon/Overview',
          addingConfiguration: 'adcon/AddingConfiguration'
        },
        A753: {
          main: 'adcon/Overview',
          addingConfiguration: 'adcon/AddingConfiguration'
        },
        A733: {
          main: 'adcon/Overview',
          addingConfiguration: 'adcon/AddingConfiguration'
        },
        A723_S4: {
          main: 'adcon/Overview',
          addingConfiguration: 'adcon/AddingConfiguration'
        },
        A753GSM: {
          main: 'adcon/Overview',
          addingConfiguration: 'adcon/AddingConfiguration'
        },
        RA440: {
          main: 'adcon/Overview',
          addingConfiguration: 'adcon/AddingConfiguration'
        }
      },
      deviceHeaders: [
        {
          title: 'Serial Number',
          field: (device) => device.metaData.addupi.serialNumber
        }
      ]
    },
    {
      _id: 'mqtt',
      name: 'MQTT Network',
      deviceHeaders: [
        {
          title: 'Serial Number',
          field: (device) => device.metaData.identifier
        }
      ]
    },
    {
      _id: 'ottml',
      name: 'OTT-ML HTTP Network',
      deviceHeaders: [
        {
          title: 'Device ID (OTT-ML)',
          field: (device) => device.metaData.identifier
        },
        {
          title: 'Device Name (OTT-ML)',
          field: (device) => device.metaData.name
        }
      ]
    },
    {
      _id: 'iota-server',
      name: 'IoTa LTE-M Network',
      deviceHeaders: [
        {
          title: 'Device ID',
          field: (device) => device.metaData.identifier
        },
        {
          title: 'IMEI',
          field: (device) => device.metaData.imei
        }
      ],
      devices: [
        {
          _id: 'iota-lte',
          title: 'IoTa LTE-M',
          component: 'iota-lte/Overview'
        }
      ]
    },
    {
      _id: 'ilevel-server',
      name: 'iLevel-GW Network',
      enabledExchangeDevices: ['ilevel-gw-4g'],
      deviceHeaders: [
        {
          title: 'Mac Address',
          field: (device) => device.metaData.macAddress
        }
      ]
    },
    {
      _id: 'hydrotel',
      name: 'Hydrotel',
      deviceHeaders: [
        {
          title: 'Serial Number',
          field: (device) => device.metaData.serialNumber
        }
      ]
    },
    {
      _id: 'cae-ftp',
      name: 'CAE Network',
      components: {
        main: 'cae/Overview'
      },
      deviceHeaders: []
    },
    {
      _id: 'ftp-app-zrxp',
      name: 'FTP Network - ZRXP',
      components: {
        main: 'common/FtpOverview',
        addingConfiguration: 'common/FtpGatewayConfig'
      },
      deviceTypes: {
        'ftp-device-zrxp': {
          main: 'ftp-device-zrxp/Overview',
          addingConfiguration: 'ftp-device-zrxp/AddingConfiguration'
        }
      },
      deviceHeaders: []
    },
    {
      _id: 'ftp-app-mis',
      name: 'FTP Network - MIS',
      components: {
        main: 'common/FtpOverview',
        addingConfiguration: 'ftp-app-mis/MisFtpConfig'
      },
      deviceTypes: {
        'ftp-device-mis': {
          main: 'ftp-device-mis/Overview',
          addingConfiguration: 'ftp-device-mis/AddingConfiguration'
        }
      },
      deviceHeaders: []
    },
    {
      _id: 'ftp-app-vulink-csv',
      name: 'FTP Network - VuLink CSV',
      components: {
        main: 'common/FtpOverview',
        addingConfiguration: 'common/FtpGatewayConfig'
      },
      deviceTypes: {
        'ftp-device-vulink-csv': {
          main: 'ftp-device-vulink-csv/Overview',
          addingConfiguration: 'ftp-device-vulink-csv/AddingConfiguration'
        }
      },
      deviceHeaders: []
    },
    {
      _id: 'ftp-app-lns-csv',
      name: 'FTP Network - LNS CSV',
      components: {
        main: 'common/FtpOverview',
        addingConfiguration: 'common/FtpGatewayConfig'
      },
      deviceTypes: {
        'ftp-device-lns-csv': {
          main: 'ftp-device-lns-csv/Overview',
          addingConfiguration: 'ftp-device-lns-csv/AddingConfiguration'
        }
      },
      deviceHeaders: []
    },
    {
      _id: 'hailsens',
      name: 'HailSens Network',
      deviceHeaders: [
        {
          title: 'Device ID',
          field: (device) => device.metaData.identifier
        }
      ]
    }
  ],
  apiKeyPermissionMapping: [
    {
      _id: 'admin',
      name: 'Local Organisation Administrator',
      permissions: [
        'organisations:get',
        'applications:get',
        'applications:create',
        'applications:edit',
        'applications:remove',
        'devices:get',
        'devices:create',
        'devices:edit',
        'devices:remove',
        'deviceTypes:get',
        'deviceLogs:get',
        'deviceLogs:create',
        'users:get',
        'networkAdapterHosts:get',
        'mqttIntegrationKeys:get',
        'firmware:get'
      ]
    },
    {
      _id: 'device-registration',
      name: 'Device Registration Key (HyComm)',
      permissions: [
        'organisations:get',
        'applications:get',
        'devices:get',
        'devices:create',
        'devices:edit',
        'devices:remove',
        'deviceTypes:get',
        'deviceLogs:get',
        'deviceLogs:create',
        'networkAdapterHosts:get',
        'mqttIntegrationKeys:get',
        'firmware:get'
      ]
    },
    {
      _id: 'viewer',
      name: 'Viewing Key',
      permissions: [
        'organisations:get',
        'applications:get',
        'devices:get',
        'deviceTypes:get',
        'deviceLogs:get',
        'users:get',
        'networkAdapterHosts:get',
        'mqttIntegrationKeys:get',
        'firmware:get'
      ]
    }
  ],
  permissionMapping: [
    {
      _id: 'globaladmin',
      name: 'IoT Middleware Administrator',
      permissions: [
        'globalAdmin',
        'organisations:get',
        'organisations:create',
        'organisations:edit',
        'organisations:remove',
        'applications:get',
        'applications:create',
        'applications:edit',
        'applications:remove',
        'devices:get',
        'devices:create',
        'devices:edit',
        'devices:remove',
        'deviceTypes:get',
        'deviceTypes:create',
        'deviceTypes:edit',
        'deviceTypes:remove',
        'deviceLogs:get',
        'deviceLogs:create',
        'deviceLogs:edit',
        'deviceLogs:remove',
        'networkAdapterHosts:get',
        'networkAdapterHosts:create',
        'networkAdapterHosts:edit',
        'networkAdapterHosts:remove',
        'mqttIntegrationKeys:get',
        'mqttIntegrationKeys:create',
        'mqttIntegrationKeys:edit',
        'mqttIntegrationKeys:remove',
        'users:get',
        'users:create',
        'users:edit',
        'users:remove',
        'apiKeys:get',
        'apiKeys:create',
        'apiKeys:edit',
        'apiKeys:remove',
        'firmware:get',
        'firmware:create',
        'firmware:edit',
        'firmware:remove'
      ]
    },
    {
      _id: 'admin',
      name: 'Local Organisation Administrator',
      permissions: [
        'admin',
        'organisations:get',
        'applications:get',
        'applications:create',
        'applications:edit',
        'applications:remove',
        'apiKeys:get',
        'apiKeys:create',
        'apiKeys:edit',
        'apiKeys:remove',
        'devices:get',
        'devices:create',
        'devices:edit',
        'devices:remove',
        'deviceTypes:get',
        'deviceLogs:get',
        'deviceLogs:create',
        'users:get',
        'networkAdapterHosts:get',
        'mqttIntegrationKeys:get',
        'firmware:get'
      ]
    },
    {
      _id: 'viewer',
      name: 'Viewing User',
      permissions: [
        'organisations:get',
        'applications:get',
        'devices:get',
        'deviceTypes:get',
        'deviceLogs:get',
        'users:get',
        'networkAdapterHosts:get',
        'mqttIntegrationKeys:get',
        'firmware:get'
      ]
    }
  ],
  screens: [
    {
      title: 'Overview',
      path: 'overview',
      icon: 'mdi-card-search'
    },
    {
      title: 'Networks',
      path: 'applications',
      icon: 'mdi-wan'
    },
    // {
    //   title: 'Devices',
    //   path: 'devices',
    //   icon: 'mdi-chip'
    // },
    {
      title: 'Data consumers',
      path: 'data-consumers',
      icon: 'mdi-database-arrow-right'
    }
  ],
  time: {
    /**
     * Timezone: If empty will default to local browser timezone, https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
     * @type {String}
     */
    // timezone: 'Europe/Berlin',
    /**
     * Default: Must be in the format: 'YYYY-MM-DD HH:mm'
     * @type {String}
     */
    default: {
      dateType: 'day'
    },
    /**
     * Locale: Defines way time is displayed
     * @type {String}
     */
    locale: 'en-gb',
    /**
     * ClockFormat: 24hr, ampm, @TODO browser (automatic)
     * @type {String}
     */
    clockFormat: '24hr',
    /**
     * EnabledDateTypes: date types that are available to users
     * @type {Array[{String}]}
     */
    enabledDateTypes: [
      'hour',
      'day',
      'month',
      'year'
    ]
  }
}
