import rest from '@feathersjs/rest-client'
import feathers from '@feathersjs/feathers'
import auth from '@feathersjs/authentication-client'
import axios from 'axios'

export default function getApi (url, router) {
  const app = feathers()

  // Need hook to handle jwt expiry
  app.hooks({
    error (context) {
      if (context.error && context.error.code === 401) {
        router.push('/')
      }
      console.log(context)
      context.message = 'Not logged in.'
      return context
    }
  })

  // Connect to a different URL
  const restClient = rest(url).axios(axios)
  // Configure an AJAX library (see below) with that client
  app.configure(restClient)
  app.configure(auth())

  return app
}
