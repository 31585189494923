import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export const InitializationState = {
  NotInitialized: 'NotInitialized',
  Initializing: 'Initializing',
  Initialized: 'Initialized'
}

export default new Vuex.Store({
  state: {
    organisationId: null,
    initializationState: InitializationState.NotInitialized
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
