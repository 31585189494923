<template>
  <v-app style="background-color: #fff;">
    <transition name="fade">
      <router-view v-if="refreshedLogin" />
    </transition>
  </v-app>
</template>

<script>
import { InitializationState } from './store'

export default {
  name: 'VApp',
  data: () => ({
    refreshedLogin: false
  }),
  async mounted () {
    const loggedIn = await this.refreshLogin()
    this.refreshedLogin = true
    if (loggedIn) {
      if (this.$route.name === 'Login') {
        this.$router.push('/dashboard')
      }
    } else {
      this.$router.push('/')
    }
  },
  methods: {
    async refreshLogin () {
      try {
        this.$set(this.$store.state, 'initializationState', InitializationState.Initializing)
        const response = await this.$api.reAuthenticate()
        this.$api.user = response.user
        this.$api.accessToken = response.accessToken
        this.$set(this.$store.state, 'organisationId', response.user.organisationId)
        console.log(this.$route.name)
        this.$set(this.$store.state, 'initializationState', InitializationState.Initialized)
        return true
      } catch (error) {
        console.error(error.message)
        return false
      }
    }
  }
}
</script>
<style>
  html {
      overflow-y: hidden !important;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  #appTitle > div {
    width: 280px !important;
  }
  .toasted {
    font-family: Roboto;
  }
  .activeMenu {
    background-color: #02576f1b;
  }
  .v-toast, .v-toast--bottom {
    font-family: 'Roboto';
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .blob {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    animation: pulse-white 2s infinite;
  }

  .primaryBlob {
    box-shadow: 0 0 0 0 rgba(2, 87, 111, 1);
    animation: pulse-primary 2s infinite;
  }

  @keyframes pulse-white {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  @keyframes pulse-primary {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(2, 87, 111, 0.25);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(2, 87, 111, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(2, 87, 111, 0);
    }
  }

  .v-btn {
      font-weight: 400 !important;
  }

  .v-card__actions {
      border-top: 1px solid rgba(160, 160, 160, 0.2);
  }

  .toasty {
      font-family: Roboto, sans-serif;
      font-size: 16px;
      font-weight: 400 !important;
  }

  .fullScreenMenu {
      display: flex !important;
      flex-direction: column;
  }
</style>
