import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: () => import('../views/Overview.vue')
      },
      {
        path: 'applications',
        name: 'Networks',
        component: () => import('../views/Applications.vue')
      },
      {
        path: 'network-hosts',
        name: 'Network hosts',
        component: () => import('../views/NetworkHosts.vue')
      },
      {
        path: 'network-hosts/:id',
        name: 'Network host details',
        component: () => import('../views/NetworkHost.vue')
      },
      {
        path: 'device-types',
        name: 'Device Types',
        component: () => import('../views/DeviceTypes.vue')
      },
      {
        path: 'device-types/:id',
        name: 'Device type details',
        component: () => import('../views/DeviceType.vue')
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('../views/Profile.vue')
      },
      {
        path: 'applications/:id',
        name: 'Network details',
        component: () => import('../views/Application.vue')
      },
      {
        path: 'applications/:id/devices/:deviceId',
        name: 'Device details',
        component: () => import('../views/Application.vue')
      },
      {
        path: 'data-consumers',
        name: 'Data consumers',
        component: () => import('../views/DataConsumers.vue')
      },
      {
        path: 'users',
        name: 'Users',
        component: () => import('../views/Users.vue')
      },
      {
        path: 'users/:id',
        name: 'User details',
        component: () => import('../views/User.vue')
      },
      {
        path: 'api-keys',
        name: 'API keys',
        component: () => import('../views/ApiKeys.vue')
      },
      {
        path: 'api-keys/:id',
        name: 'API key details',
        component: () => import('../views/ApiKey.vue')
      },
      {
        path: 'firmware',
        name: 'Firmware',
        component: () => import('../views/Firmwares.vue')
      },
      {
        path: 'firmware/:id',
        name: 'Firmware details',
        component: () => import('../views/Firmware.vue')
      },
      {
        path: 'queues',
        name: 'Queues',
        component: () => import('../views/Queues.vue')
      },
      {
        path: 'organisation',
        name: 'Organisation',
        component: () => import('../views/Organisation.vue')
      },
      {
        path: 'devices',
        name: 'Devices',
        component: () => import('../views/Devices.vue')
      },
      {
        path: 'offlineDataConsumers',
        name: 'Offline data consumers',
        component: () => import('../views/OfflineDataConsumers.vue')
      },
      {
        path: 'ssh-keys',
        name: 'SSH keys',
        component: () => import('../views/SshKeys.vue')
      },
      {
        path: 'ssh-keys/:id',
        name: 'SSH keys',
        component: () => import('../views/SshKey.vue')
      },
      {
        path: '/dashboard/*',
        name: 'Not Found ',
        component: () => import('../components/ContentNotFound.vue')
      }
      // {
      //   path: 'device/:id',
      //   name: 'Device',
      //   component: () => import('../views/Device.vue')
      // }
    ]
  },
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '*',
    name: 'Not Found',
    component: () => import('../components/ContentNotFound.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes
})
const defaultTitle = 'IoT Middleware - HyQuest Solutions'
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.name + ' | IoT Middleware' || defaultTitle
  })
})

export default router
