/* eslint-disable vue/component-definition-name-casing */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
// import moment from 'moment'
import vueMoment from 'vue-moment'
import VueToast from 'vue-toast-notification'
import JsonViewer from 'vue-json-viewer'
import Highcharts from 'highcharts'
import noData from 'highcharts/modules/no-data-to-display'
import exportingInit from 'highcharts/modules/exporting'
import exportingData from 'highcharts/modules/export-data'
import offlineExporting from 'highcharts/modules/offline-exporting'
// Import one of the available themes
// import 'vue-toast-notification/dist/theme-default.css'
import '@/assets/toasts.css'
import VueHighcharts from 'vue-highcharts'
import baseConfig from '@/AppConfig'
import getApi from './Api'
import 'moment/locale/en-gb'
Vue.use(vueMoment)
Vue.use(JsonViewer)
exportingInit(Highcharts)
exportingData(Highcharts)
offlineExporting(Highcharts)
noData(Highcharts)
Vue.use(VueHighcharts)
Vue.use(VueToast)
Vue.config.productionTip = false

fetch(import.meta.env.VITE_PUBLIC_PATH + 'config.json').then(res => res.json()).then(dynamicConfig => {
  baseConfig.url = dynamicConfig.apiUrl
  baseConfig.appTitle = dynamicConfig.appTitle
  baseConfig.publicPath = dynamicConfig.publicPath
  Vue.prototype.$config = baseConfig

  // const authHeader = localStorage.getItem('apiKey') ? localStorage.getItem('apiKey') : ''
  Vue.prototype.$api = getApi(Vue.prototype.$config.url, router)
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})
